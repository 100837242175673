/* eslint-disable */
import { configureStore } from '@reduxjs/toolkit'

import setReducer from 'src/redux/Reducer/sidebar/setReducer'

import departmentModalReducer from 'src/redux/Reducer/departmentReducer/ModalSlice'
import departmentDeleteModalReducer from 'src/redux/Reducer/departmentReducer/DeleteSlice'
import doctorInfoReducer from './redux/Reducer/doctorDataReducer/doctorInfoSlice'
import countriesInfoModalReducer from 'src/redux/Reducer/countriesReducer/countriesSlice'

import { upsertServiceReducer } from './redux/Reducer/servicesReducers'
import { deleteServiceReducer } from './redux/Reducer/servicesReducers'
import showMedReducer from './redux/Reducer/MedicineReducer/showMedSlice'
import deleteMedReducer from './redux/Reducer/MedicineReducer/deleteMedSlice'

import { userInfoReducer } from './redux/Reducer/patientDataReducer'
import prescriptionMedicineReducer from './redux/Reducer/prescriptionMedicine/prescriptionMedicineSlice'
import billServiceReducer from './redux/Reducer/patientPayment/BillServicesSlice'
import addMedicineReducer from './redux/Reducer/prescriptionMedicine/AddMedicineSlice'
import roleSlice from './redux/Reducer/roleReducer/roleSlice'
import dataForEditRoleFlagReducer from './redux/Reducer/StorageDataForEditRoleReducer/StorageDataForEditRoleReducerFlagSlice'
import tokenSlice from './redux/Reducer/token/tokenSlice'
import put_postReducer from "../src/views/pages/new/patient/patientCom/patientForm/PutPostSlice";
const store = configureStore({
  reducer: {
    sidebar: setReducer,
    countriesInfoModal: countriesInfoModalReducer,
    departmentDeleteModal: departmentDeleteModalReducer,
    departmentModal: departmentModalReducer,
    addServicesModal: upsertServiceReducer,

    doctorInfo: doctorInfoReducer,
    deleteServicesModal: deleteServiceReducer,
    UpsertMedicineModal: showMedReducer,
    deleteMedModal: deleteMedReducer,
    userInfo: userInfoReducer,
    medicine: prescriptionMedicineReducer,
    service: billServiceReducer,
    newMedicine: addMedicineReducer,
    rolesSlice: roleSlice,
    dataFlag: dataForEditRoleFlagReducer,
    tokenModal: tokenSlice,
    //
    table: put_postReducer,
  },
})
export default store
